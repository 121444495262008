body,
html,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Titillium Web';
}

body {
  box-sizing: border-box;
}
